body {
  background: rgb(249, 249, 249);
}
#root {
  background: rgb(249, 249, 249);
}
.btn-primary {
  background-color: #00cfff !important;
  border-color: #00cfff !important;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff !important;
  background-color: #1593c9 !important;
  border-color: #148abe !important;
}
.btn-outline-danger {
  color: #ff2e2e !important;
  border-color: #ff2e2e !important;
}
.btn-outline-danger:hover {
  color: #fff !important;
  background-color: #ff2e2e !important;
  border-color: #ff2e2e !important;
}
.card {
  margin-bottom: 1.875rem;
  background-color: #fff !important;
  transition: all 0.5s ease-in-out !important;
  position: relative !important;
  /* border: 1px solid #00cfff !important; */
  border-radius: 1.25rem;
  box-shadow: 0 12px 23px 0 rgb(172 57 212 / 4%);
  height: calc(100% - 30px);
}
.card-header {
  border-color: #f0f1f5 !important;
  position: relative;
  background: transparent !important;
  padding: 1.5rem 1.875rem 1.25rem !important;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
.card-body {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.875rem !important;
}
.modal-content {
  border-radius: 1.25rem !important;
}
.modal-body {
  padding: 1.875rem !important;
}
.light.btn-danger {
  background-color: #ffc7c7 !important;
  border-color: #ffc7c7 !important;
  color: #ff2e2e;
}
.light.btn-danger:hover {
  background-color: #ff2e2e !important;
  border-color: #ff2e2e !important;
  color: #fff;
}
@media only screen and (max-width: 575px) {
  .card-header {
    padding: 1.25rem 1rem;
  }
  .card-body {
    padding: 1rem;
  }
}

/* OVERRIDE Width Column of React-data-table  */
.fvAeSn {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  /* max-width: 100% !important; */
  width: auto !important;
  min-width: 25vh !important;
}

.rdt_TableCell div:first-child {
  white-space: pre-line !important;
  overflow: auto;
  text-overflow: clip;
  width: auto;
}

.rdt_TableCol div:first-child {
  white-space: pre-line !important;
  overflow: auto;
  text-overflow: clip;
  width: auto;
}

.rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol,
.rdt_TableBody > .rdt_TableRow > .rdt_TableCell  {
  padding: 0 !important;
  margin-right: 1.5rem;
}

.switch {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.label-switch {
  padding: 0;
  margin-bottom: 0;
  line-height: 0;
}
.switch + .label {
  position: relative;
  display: inline-block;
  margin: 0;
  line-height: 20px;
  min-height: 18px;
  min-width: 18px;
  font-weight: normal;
  cursor: pointer;
}
.switch + .label {
  margin: 0 4px;
  min-height: 24px;
}
.switch + .label::before {
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  height: 20px;
  overflow: hidden;
  border-radius: 15px;
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #ccc;
  text-align: left;
  float: left;
  padding: 0;
  width: 52px;
  text-indent: -21px;
  margin-right: 0;
  -webkit-transition: text-indent 0.3s ease;
  -o-transition: text-indent 0.3s ease;
  transition: text-indent 0.3s ease;
  top: auto;
}

.switch.switch-ls + .label::before {
  content: "Cash In";
  box-shadow: none;
  border-width: 0;
  font-size: 16px;
  background-color: #a9a9a9;
  color: #f2f2f2;
  width: 120px;
  height: 32px;
  line-height: 32px;
  text-indent: 40px;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
}
.switch.switch-ls + .label::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 15px;
  border-radius: 50%;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  width: 28px;
  height: 28px;
  text-align: center;
  background-color: #f2f2f2;
  border: 4px solid #f2f2f2;
  -webkit-transition: left 0.2s ease;
  -o-transition: left 0.2s ease;
  transition: left 0.2s ease;
}
.switch.switch-ls:checked + .label::before {
  content: "Cash Out";
  text-indent: 12px;
  color: #fff;
  border-color: #b7d3e5;
}
.switch-primary > .switch.switch-ls:checked + .label::before {
  background-color: #337ab7;
}
.switch.switch-ls:checked + .label::after {
  left: 6.5rem;
  background-color: #fff;
  border: 4px solid #fff;
  text-shadow: 0 -1px 0 rgba(0, 200, 0, 0.25);
}